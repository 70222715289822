import React, { useState, useCallback } from 'react';

import { toast } from 'react-toastify';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import SEO from '../../seo';
import api from '../../../services/api';

import Loading from '../../Loading/Backgrop';

import { View, Grid } from './styles';

export default function Contato() {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
  });

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    if (!form.name) {
      toast.warning('Preencha o campo nome.');
      return;
    }

    if (!form.email) {
      toast.warning('Preencha o campo email.');
      return;
    }

    if (!form.subject) {
      toast.warning('Preencha o campo assunto.');
      return;
    }

    if (!form.phone) {
      toast.warning('Preencha o campo celular.');
      return;
    }

    if (!form.message) {
      toast.warning('Preencha o campo mensagem.');
      return;
    }

    setLoading(true);
    try {
      await api.post('contato', form);
      toast.success('Mensagem enviada com sucesso.');
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  }, [form]);

  return (
    <>
      <SEO title="Contato" />
      <View>
        <Grid>
          <form
            method="post"
            onSubmit={e => handleSubmit(e)}
          >
            <h3>Fale Conosco</h3>
            <p>Fique à vontade para entrar em contato conosco para saber mais sobre nossa página. Estamos sempre prontos para responder suas perguntas.</p>

            <TextField
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              name="name"
              onChange={e => setForm({ ...form, name: e.target.value })}
            />

            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              name="email"
              onChange={e => setForm({ ...form, email: e.target.value })}
            />

            <TextField
              id="outlined-basic"
              label="Assunto"
              variant="outlined"
              name="subject"
              onChange={e => setForm({ ...form, subject: e.target.value })}
            />

            <TextField
              id="outlined-basic"
              label="Celular / Whatsapp"
              variant="outlined"
              name="phone"
              onChange={e => setForm({ ...form, phone: e.target.value })}
            />

            <textarea
              name="message"
              placeholder="Preencha aqui.."
              onChange={e => setForm({ ...form, message: e.target.value })}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {loading ? (
                'Carregando...'
              ) : (
                'Enviar Mensagem'
              )}
            </Button>

          </form>
        </Grid>
      </View>

      <Loading loading={loading} />
    </>
  );
}
