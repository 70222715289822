import styled from 'styled-components';

export const View = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 9;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Grid = styled.div`
  display: flex;
  flex: 1;
  max-width: 450px;
  flex-direction: column;

  p {
    font-size: 14px;
    margin-bottom: 12px;
  }

  textarea {
    height: 100px;
    border: 0;
    border: 1px solid #ccc;
    font-size: 17px;
    padding: 8px;
    font-weight: 300;
    border-radius: 5px;
    margin-top: 10px;

    &:hover {
      border: 1px solid #000;
    }
  }

  .redessociais {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 12px;
    padding-top: 12px;

    div {
      width: 100%;
    }

    div + div {
      margin-left: 4px;
    }

    .google {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      border: 0;
      background: #E96358;
      padding: 12px 8px;
      border-radius: 4px;
      color: #fff;

      svg {
        margin-right: 4px;
      }

      &:hover {
        background: #E44336;
      }
    }

    .facebook {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      border: 0;
      background: #006FDD;
      color: #fff;
      padding: 12px 8px;
      border-radius: 4px;

      svg {
        margin-right: 4px;
      }

      &:hover {
        background: #178BFF;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      background: #fff;
    }

    div + div {
      margin-top: 8px;
    }

    button {
      margin-top: 8px;
    }

    .separa {
      display: flex;
      justify-content: center;
      margin-top: 8px;
    }

    h3 {
      display: flex;
      justify-content: center;
      margin-bottom: 14px;
      color: #999;
      font-size: 24px;
    }
  }
`;
